<template>
  <el-drawer
    title="消耗"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    :before-close="() => $emit('close')"
    size="600px"
  >
    <el-form label-width="100px">
      <el-form-item label="日期">
        <el-date-picker
          v-model="form.selectDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="金额">
        <el-input-number
          v-model="form.costAmount"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="现金">
        <el-input-number
          v-model="form.cashBase"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="书籍">
        <el-select
          v-model="form.booksId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入书籍名称"
          :remote-method="handleSearch"
          :loading="searchLoading"
        >
          <el-option
            v-for="item in bookOption"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签类型">
        <el-radio
          v-model="form.labelType"
          :disabled="!form.id"
          :label="0"
          border
          >抖音</el-radio
        >
        <el-radio
          v-model="form.labelType"
          :disabled="!form.id"
          :label="1"
          border
          >爱奇艺</el-radio
        >
        <el-radio
          v-model="form.labelType"
          :disabled="!form.id"
          :label="2"
          border
          >朋友圈</el-radio
        >
        <el-radio
          v-model="form.labelType"
          :disabled="!form.id"
          :label="3"
          border
          >百度</el-radio
        >
        <br />
        <el-radio
          v-model="form.labelType"
          style="margin-top: 10px"
          :disabled="!form.id"
          :label="4"
          border
          >广点通GDT
        </el-radio>
        <el-radio
          v-model="form.labelType"
          style="margin-top: 10px"
          :disabled="!form.id"
          :label="5"
          border
          >朋友圈-宇尘
        </el-radio>
        <el-radio
          v-model="form.labelType"
          style="margin-top: 10px"
          :disabled="!form.id"
          :label="6"
          border
          >知乎
        </el-radio>
        <el-radio
          v-model="form.labelType"
          style="margin-top: 10px"
          :disabled="!form.id"
          :label="7"
          border
          >vivo
        </el-radio>
        <el-radio
          v-model="form.labelType"
          style="margin-top: 10px"
          :disabled="!form.id"
          :label="8"
          border
          >公众号
        </el-radio>
      </el-form-item>
    </el-form>
    <div class="drawer-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { getBookList, editConsume } from "@/api/account";

export default {
  name: "edit",
  props: {
    show: Boolean,
    info: Object,
    accountChannelId: Number,
    labelType: [Number, String],
  },
  data() {
    return {
      searchLoading: false,
      bookList: [],
      form: {},
      currentBook: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        const data = this.info;
        if (data) {
          const { booksId } = data;
          this.form = {
            selectDate: data.selectDate,
            costAmount: data.costAmount,
            id: data.id,
            accountChannelId: this.accountChannelId,
            cashBase: data.cashBase,
            labelType: data.labelType,
            booksId: booksId,
          };
          if (this.bookList.some((book) => book.id === booksId) || !booksId) {
            this.currentBook = [];
          } else {
            this.currentBook = [{ id: booksId, bookName: data.bookName }];
          }
        } else {
          this.form = {
            selectDate: null,
            costAmount: null,
            accountChannelId: this.accountChannelId,
            cashBase: 0,
            labelType: this.labelType,
            booksId: null,
          };
          this.currentBook = [];
        }
      }
    },
  },
  computed: {
    bookOption() {
      return this.currentBook.concat(this.bookList);
    },
  },
  methods: {
    handleSubmit() {
      const { selectDate, costAmount, cashBase } = this.form;
      if (!selectDate) {
        this.$message.error("请选择消耗日期");
        return false;
      }
      if (typeof costAmount !== "number") {
        this.$message.error("请输入消耗金额");
        return false;
      }
      if (typeof cashBase !== "number") {
        this.$message.error("请输入现金");
        return false;
      }
      editConsume(this.form).then(() => {
        this.$message.success("保存成功");
        this.$emit("close");
        this.$emit("refresh");
      });
    },
    handleSearch(query) {
      this.searchLoading = true;
      getBookList({
        page: 1,
        pageSize: 30,
        bookName: query,
      })
        .then((res) => {
          this.bookList = res.list;
        })
        .finally(() => {
          this.searchLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-drawer__open .el-drawer.rtl {
  // width: 700px !important;
}
/deep/ .el-radio {
  margin-right: 0px;
}
.drawer-footer {
  // margin: 50px;
  // display: flex;
  // justify-content: flex-end;
  // align-items: center;
  margin-top: 40px;
  text-align: center;
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
