<template>
  <el-drawer
    title="公众号列表"
    center
    :append-to-body="true"
    :visible.sync="show"
    top="5vh"
    size="900px"
    :close-on-click-modal="false"
    :before-close="() => $emit('close')"
    style="border-radius: 5px"
  >
    <el-table :data="gridData" height="80vh">
      <el-table-column property="selectDate" label="投放日期"></el-table-column>
      <el-table-column property="channelName" label="公众号"></el-table-column>
      <el-table-column property="bookName" label="书籍"></el-table-column>
      <!-- <el-table-column property="cashBase" label="现金基数"></el-table-column> -->
      <el-table-column property="costAmount" label="消耗金额"></el-table-column>
      <el-table-column min-width="80" prop="labelType" label="标签类型">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.labelType === 0" :style="getTypeColor(0)"
            >抖音</el-tag
          >
          <el-tag v-else-if="scope.row.labelType === 1" :style="getTypeColor(1)"
            >爱奇艺</el-tag
          >
          <el-tag v-else-if="scope.row.labelType === 2" :style="getTypeColor(2)"
            >朋友圈</el-tag
          >
          <el-tag v-else-if="scope.row.labelType === 3" :style="getTypeColor(3)"
            >百度</el-tag
          >
          <el-tag v-else-if="scope.row.labelType === 4" :style="getTypeColor(4)"
            >广点通GDT</el-tag
          >
          <el-tag v-else-if="scope.row.labelType === 5" :style="getTypeColor(5)"
            >朋友圈-宇尘</el-tag
          >
          <el-tag
            v-else-if="scope.row.labelType === 6"
            :style="getTypeColor(6)"
          >
            知乎
          </el-tag>
          <el-tag v-else-if="scope.row.labelType === 7" :style="getTypeColor(7)"
            >vivo</el-tag
          >
          <el-tag v-else-if="scope.row.labelType === 8" :style="getTypeColor(8)"
            >公众号</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column width="100" prop="action" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="15"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <consume-edit
      :show="isEdit"
      @close="isEdit = false"
      @refresh="getList"
      :info="editForm"
      :account-channel-id="editForm.accountChannelId"
    />
    <span slot="footer" class="dialog-footer">
      <el-button size="large" @click="$emit('close')">关 闭</el-button>
    </span>
  </el-drawer>
</template>

<script>
import { getBookAccount } from "@/api/account";
import ConsumeEdit from "../../platform/consume/edit";

export default {
  name: "account",
  props: {
    show: Boolean,
    bookId: Number,
  },
  data() {
    return {
      gridData: [],
      page: 1,
      loading: false,
      total: 0,
      isEdit: false,
      editForm: {},
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.handlePageChange(1);
      }
    },
  },
  methods: {
    handleEdit(data) {
      this.isEdit = true;
      this.editForm = data;
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      getBookAccount(this.bookId, {
        page: this.page,
        pageSize: 15,
      })
        .then((res) => {
          this.total = res.total;
          this.gridData = res.list;
        })
        .finally(() => (this.loading = false));
    },
    getTypeColor(type) {
      const colorList = [
        "rgba(253,93,90)",
        "rgba(255,184,94)",
        "rgba(0,191,138)",
        "rgba(63,128,255)",
        "rgba(98,115,224)",
        "rgba(108,129,152)",
        "rgba(255,122,184)",
        "rgba(34,199,177)",
        "rgba(171,116,233)",
        "rgba(183,183,183),",
      ];
      const regExp = /\d+/g;
      const colorInfo = colorList.map((item) => {
        return item.match(regExp).join(",");
      });
      return {
        color: `rgba(${colorInfo[type]})`,
        backgroundColor: "#fff",
        borderColor: `rgba(${colorInfo[type]})`,
      };
    },
  },
  components: {
    ConsumeEdit,
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-drawer.rtl {
  border-radius: 5px;
}
</style>
