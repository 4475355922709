<template>
  <el-container class="height-calc-type1">
    <aside-category
      :cateType="3"
      @asideSelect="handleSelect"
      @asideToEdit="toCategoryEdit"
      @asideBooStatus="handleBookStatusChange"
      ref="_asideCategory"
    ></aside-category>
    <el-container>
      <el-main class="main-cont box-shadow-light-grey border-radius-6">
        <div class="book-header">
          <div class="leftSelect">
            <el-button @click="getList">刷新数据</el-button>
            <el-button
              class="new-book margin-left-twentyFour"
              type="primary"
              @click="toEdit(null)"
              >新增书籍</el-button
            >
          </div>
          <div class="rightSelect">
            <el-select
              v-if="showClassCol"
              v-model="allBookClassify"
              placeholder="选择分类"
              clearable
              @change="allBookClassifyChange"
            >
              <el-option
                v-for="item in classifyName"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-select
              v-model="bookstatu"
              placeholder="选择书籍状态"
              clearable
              @change="handleBookStatusChange"
              style="width: 130px; margin-left: 20px"
            >
              <el-option label="测书" :value="1"> </el-option>
              <el-option label="观察" :value="2"> </el-option>
              <el-option label="放量" :value="3"> </el-option>
              <el-option label="放弃" :value="4"> </el-option>
            </el-select> -->
            <el-input
              placeholder="请输入书名"
              v-model="search"
              style="width: 200px; margin-left: 20px"
              clearable
              @keydown.enter.native="handlePageChange(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handlePageChange(1)"
              ></el-button>
            </el-input>
          </div>
        </div>
        <el-table
          v-loading="loading"
          :data="dataList"
          height="calc(100vh - 240px)"
          style="width: 100%"
          @cell-click="handleCellClick"
        >
          <!-- <el-table-column prop="id" label="ID" width="60"> </el-table-column> -->
          <el-table-column prop="bookName" label="书名" width="150">
            <template slot-scope="scope">
              <span class="drawer-text">{{ scope.row.bookName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="minRate" label="最低roi">
            <template slot-scope="scope">
              {{ scope.row.minRate ? scope.row.minRate + "%" : "" }}
            </template>
          </el-table-column>
          <el-table-column prop="roiSetting" width="120" label="转化成本">
            <template slot-scope="scope">
              <div v-if="scope.row.roiSetting">
                <el-tag
                  style="margin: 0 2px 2px 0"
                  :key="n"
                  v-for="(m, n) in labelName(scope.row.roiSetting)"
                  type="success"
                  size="normal"
                >
                  {{ labelList[m.label].label + ":" + m.value }}</el-tag
                >
              </div>
              <div v-else></div>
            </template>
          </el-table-column>
          <el-table-column prop="bookAlias" label="别名" width="90">
          </el-table-column>
          <el-table-column
            prop="categoryName"
            label="所在分类"
            v-if="showClassCol"
          >
          </el-table-column>
          <el-table-column prop="selectBookUserName" label="选书人">
          </el-table-column>
          <el-table-column prop="testUserIds" label="测书人">
            <template slot-scope="scope">
              {{ scope.row.testUsersList | getTestUsersName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="startDate"
            width="110"
            label="开始时间"
          ></el-table-column>
          <el-table-column
            prop="endDate"
            width="110"
            label="结束时间"
          ></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              {{ scope.row.status | getStatus }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" width="150px" label="备注">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>{{ scope.row.remark }}</p>
                <p slot="reference" class="inner-text">
                  {{ scope.row.remark }}
                </p>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            width="230"
            prop="action"
            align="center"
            label="操作"
          >
            <template slot-scope="scope">
              <div class="">
                <el-button
                  class="button-small"
                  @click="handleAccountShow(scope.row)"
                  type="warning"
                  >公众号列表</el-button
                >
                <!-- <el-button class="button-small" @click="toBookStatus(scope.row)"
                >书籍状态</el-button
              > -->
                <el-button
                  class="button-small"
                  type="primary"
                  @click="toEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  class="button-small"
                  type="danger"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px; text-align: right; background-color: #fff"
          background
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <span slot="footer" class="dialog-footer">
          <el-button size="large" @click="handleClose">关 闭</el-button>
        </span>
        <el-drawer
          :visible.sync="isEdit"
          direction="rtl"
          size="550px"
          :title="current ? '编辑书籍' : '新增书籍'"
        >
          <edit
            :show="isEdit"
            :book="current"
            :originClassifyList="classifyList"
            :categoryId="Number(activeIndex)"
            @close="isEdit = false"
            @refresh="handlePageChange(1)"
          />
        </el-drawer>
        <account-list
          :show="showAccount"
          :book-id="current ? current.id : null"
          @close="showAccount = false"
        />
        <!-- 书籍状态操作抽屉 -->
        <!-- <books-status
          :show="isShowBookStatus"
          :bookData="currentBook"
          @close="isShowBookStatus = false"
        /> -->
      </el-main>
      <!-- 编辑新增分类抽屉 -->
      <el-drawer
        :title="`${editForm.id ? '编辑' : '新增'}分类`"
        :visible.sync="isCategoryEdit"
        size="450px"
      >
        <el-form label-width="60px">
          <el-form-item label="名称">
            <el-input v-model="editForm.name" placeholder="分类名称" />
          </el-form-item>
          <el-form-item label="类别">
            <el-select
              v-model="editForm.type"
              style="width: 100%"
              :clearable="false"
              disabled
            >
              <el-option label="客服消息" :value="1"></el-option>
              <el-option label="巨量引擎" :value="2"></el-option>
              <el-option label="书籍管理" :value="3"></el-option>
              <el-option label="板块管理" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="isCategoryEdit = false">取 消</el-button>
          <el-button type="primary" @click="handleSubmitCategoryEdit"
            >确 定</el-button
          >
        </div>
      </el-drawer>
    </el-container>
    <!-- 公共抽屉 -->
    <common-drawer
      :showDrawer="showCommonDrawer"
      size="90%"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
  </el-container>
</template>

<script>
import { getBookList, deleteBook } from "@/api/account";
import { labelList } from "@/assets/js/options";
import { formatTime, getRecentlyWeek } from "@/assets/js/utils";
import {
  getClassifyList,
  editClassify,
  // changeClassify,
} from "@/api/classify";
import Edit from "./edit";
import AccountList from "./account";
// import booksStatus from "./bookStatus";
import AsideCategory from "../category";
export default {
  name: "index",
  data() {
    return {
      show: true,
      search: null,
      dataList: [],
      page: 1,
      total: 0,
      loading: false,
      current: null,
      isEdit: false,
      isCategoryEdit: false,
      showAccount: false,
      activeIndex: null,
      editForm: {
        type: 3,
        name: "",
      },
      type: "service",
      classifyList: null,
      allBookClassify: "",
      bookStatus: [], // 书籍状态
      currentBook: {},
      isShowBookStatus: false,
      // 跳转书籍消耗相关
      nextParams: {},
      intervalBook: null,
      showCommonDrawer: false,
      showTemplate: null,
      formatTime,
      bookstatu: null,
      labelList,
    };
  },
  computed: {
    classifyName: function () {
      if (this.classifyList) {
        return this.classifyList;
      }
      return "undefined";
    },
    showClassCol: function () {
      return !this.activeIndex || this.activeIndex == -1;
    },
    labelName() {
      return function (data) {
        let value = JSON.parse(data);
        let tmpArr = [];
        for (const key in value) {
          tmpArr.push({
            label: parseInt(key),
            value: value[key],
          });
        }
        return tmpArr;
      };
    },
  },
  filters: {
    getStatus: function (value) {
      switch (value) {
        case 1:
          return "测书";
        case 2:
          return "观察";
        case 3:
          return "放量";
        case 4:
          return "放弃";
        default:
          return "";
      }
    },
    getTestUsersName: function (value) {
      if (!value) {
        return "";
      }
      return value
        .map((item) => {
          return item.testUserName;
        })
        .join(",");
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.handlePageChange(1);
      }
    },
    activeIndex(newVal) {
      this.search = null;
      this.handlePageChange(1);
    },
  },
  methods: {
    handleBookStatusChange(value) {
      if (value == this.bookstatu) {
        this.bookstatu = null;
      } else {
        this.bookstatu = value;
      }
      // console.log(this.bookstatu, "bookstatu");
      this.getList();
    },
    // 表格点击事件
    handleCellClick(row, column) {
      if (column.label !== "书名") {
        return false;
      }
      this.showTemplate = "intervalBook";
      this.showCommonDrawer = true;
      const tempTime = getRecentlyWeek(true);
      this.nextParams = {
        selectTime: tempTime,
        beginDate: tempTime[0],
        endDate: tempTime[1],
        booksId: row.id,
      };
    },
    // 书籍状态操作
    toBookStatus(data) {
      this.currentBook = data;
      this.isShowBookStatus = true;
    },
    handleAccountShow(data) {
      this.current = data;
      this.showAccount = true;
    },
    // 查找对应分类下的数据
    allBookClassifyChange() {
      getBookList({
        page: this.page,
        pageSize: 15,
        bookName: this.search,
        categoryId: this.allBookClassify,
        dataType: 1,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toEdit(data) {
      this.current = data;
      this.isEdit = true;
    },
    handleDelete(data) {
      this.$confirm("此操作将永久删除该书籍, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteBook(data.id).then(() => {
            this.handlePageChange(1);
          });
        })
        .catch(() => {});
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      getBookList({
        page: this.page,
        pageSize: 15,
        bookName: this.search,
        categoryId: this.activeIndex == -1 ? "" : this.activeIndex,
        dataType: 1,
        status: this.bookstatu,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.search = "";
      this.$emit("close");
    },
    handleSelect(item) {
      item < 0 ? (this.activeIndex = "") : (this.activeIndex = item);
      sessionStorage.setItem("classify", item);
    },
    toCategoryEdit(data, type = 3) {
      if (data) {
        this.editForm = {
          type: data.type,
          name: data.name,
          id: data.id,
        };
      } else {
        this.editForm = {
          type,
          name: "",
        };
      }
      this.isCategoryEdit = true;
    },
    // 新增书籍分类
    handleSubmitCategoryEdit() {
      if (this.editForm.name) {
        editClassify(this.editForm).then((res) => {
          if (this.type === "service") {
            this.$refs._asideCategory.init();
          } else {
            // this.getOceanClassify();
          }
          this.$message.success("保存成功");
          this.isCategoryEdit = false;
        });
      } else {
        this.$message.error("请输入分类名称");
      }
    },

    // getOceanClassify() {
    //   this.loading = true;
    //   getClassifyList({
    //     type: 2,
    //     isPage: 1,
    //     page: this.page,
    //   })
    //     .then((res) => {
    //       this.oceanClassify = res.list;
    //       this.total = res.total;
    //     })
    //     .finally(() => (this.loading = false));
    // },
  },
  components: {
    Edit,
    AccountList,
    AsideCategory,
    // booksStatus,
  },
  created() {
    getClassifyList({
      type: 3,
    })
      .then((res) => {
        this.classifyList = res;
        // this.classify = sessionStorage.getItem("classify");

        // res.length > 0
        //   ? (this.activeIndex = `${res[0].id}`)
        //   : (this.activeIndex = -1);
        let tpmActiveIndex = res.length > 0 ? res[0].id : -1;
        this.activeIndex = sessionStorage.getItem("classify") || tpmActiveIndex;
      })
      .finally(() => (this.loading = false));
  },
  // mounted() {
  //   this.handlePageChange(1);
  // },
};
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  box-sizing: border-box;
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.el-header {
  padding: 0;
  margin: 0 16px;
}
.book-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding-bottom: 20px;
  .el-button.new-book {
    margin-bottom: 0 !important;
  }
}
.el-main .main-cont {
  margin: 0 16px 16px;
  margin-bottom: 0;
  background-color: #fff;
}
.el-table__body-wrapper {
  height: 100%;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
.action-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.inner-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
</style>
