<template>
  <div class="box-shadow-light-grey">
    <el-aside
      width="220px "
      style="height: calc(100vh - 160px)"
      class="side-bar border-radius-6"
    >
      <div class="search-cont">
        <el-button-group style="width: 100%; margin-bottom: 10px">
          <el-button class="button-middle" type="primary" @click="init"
            >刷新数据</el-button
          >
          <el-button class="button-middle" type="primary" @click="toEdit(null)"
            >新增分类</el-button
          >
        </el-button-group>
        <el-input
          placeholder="分类名称"
          v-model="search"
          class="input-with-select"
          @keydown.enter.native="init"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="init"
          ></el-button>
        </el-input>
      </div>
      <div class="category-list">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          @select="handleAsideSelect"
        >
          <el-menu-item
            v-for="item in classifyList"
            :index="`${item.id}`"
            :key="item.id"
          >
            <template slot="title">
              <div class="part-info">
                <span class="class-name">{{ item.name }}</span>
                <el-dropdown
                  v-if="item.id !== -1"
                  trigger="click"
                  @command="(command) => handleCommand(command, item)"
                >
                  <span
                    class="el-dropdown-link"
                    :style="{
                      color: item.status === 0 ? '#fd5d5a' : '#00bf8a',
                    }"
                  >
                    {{ item.status === 0 ? "已禁用" : "启用中" }}
                    <i
                      class="el-icon-arrow-down el-icon--right"
                      style="font-size: 14px"
                    ></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="edit">编辑</el-dropdown-item>
                    <template>
                      <el-dropdown-item
                        v-if="item.status === 0"
                        style="color: #00bf8a"
                        command="enable"
                        >启用
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-else
                        style="color: #fd5d5a"
                        command="disable"
                        >禁用</el-dropdown-item
                      >
                    </template>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="book-status" v-if="$route.path === '/bookManagement'">
        <el-menu
          class="el-menu-demo"
          :default-active="currentBookStatus"
          @select="handleChangeBookStatus"
        >
          <el-menu-item index="1" :key="1">
            <template slot="title">
              <span class="status" slot="title">测书</span>
            </template>
          </el-menu-item>
          <el-menu-item index="2" :key="2">
            <template slot="title">
              <span class="status" slot="title">观察</span>
            </template>
          </el-menu-item>
          <el-menu-item index="3" :key="3">
            <template slot="title">
              <span class="status" slot="title">放量</span>
            </template>
          </el-menu-item>
          <el-menu-item index="4" :key="4">
            <template slot="title">
              <span class="status" slot="title">放弃</span>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
    </el-aside>
  </div>
</template>

<script>
import { getClassifyList, changeClassify } from "@/api/classify";
import { classifyMenu } from "@/assets/js/nav";
export default {
  props: {
    cateType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      activeIndex: null,
      classifyList: [],
      loading: false,
      search: "",
      classifyMenu,
      currentBookStatus: "",
    };
  },
  methods: {
    handleChangeBookStatus(value) {
      if (this.currentBookStatus == value) {
        this.currentBookStatus = null;
      } else {
        this.currentBookStatus = value;
      }

      this.$emit("asideBooStatus", value);
    },
    init() {
      this.loading = true;
      getClassifyList({
        type: this.cateType,
        name: this.search,
      })
        .then((res) => {
          this.classifyList = res;
          if (this.cateType === 3) {
            this.classifyList.push({
              id: -1,
              name: "所有书籍",
              status: 1,
              type: 3,
            });
          }
          const classify = sessionStorage.getItem("classify");
          if (res.length > 0) {
            this.activeIndex = classify || `${res[0].id}`;
          } else {
            this.activeIndex = classify;
          }
        })
        .finally(() => (this.loading = false));
    },
    toEdit(data) {
      this.$emit("asideToEdit", data);
    },
    handleAsideSelect(item) {
      this.$emit("asideSelect", item);
    },
    handleCommand(command, item) {
      switch (command) {
        case "edit":
          this.toEdit(item);
          break;
        case "enable":
          this.handleChangeStatus(item, 1);
          break;
        case "disable":
          this.handleChangeStatus(item, 0);
          break;
      }
    },
    handleChangeStatus(item, status) {
      this.$confirm(`是否${status === 1 ? "启用" : "禁用"}该分类`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          changeClassify(item.id, status).then(() => {
            this.init();
            this.$message.success("操作成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped lang="scss" >
.search-cont {
  padding: 15px;
  border-bottom: 1px solid #ececec;
  background-color: #fff;
}
.category-list {
  /* height:500px; */
  /* overflow: auto; */
  background-color: #fff;
  // max-height: 500px;
  flex: 1;
  overflow-y: auto;
}
.book-status {
  margin-top: 20px;
  /deep/ .status {
    font-size: 16px !important;
  }
}

.el-menu-demo {
  border: 0;
  overflow: auto;
  flex: 1;
}

.side-bar {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  /* background-color: #fff; */
}

.input-with-select {
  width: 100%;
}
.part-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.class-name {
  /* flex: 1; */
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sub-menu .el-menu-item,
.el-submenu__title {
  height: 45px;
  line-height: 45px;
}

.sub-menu {
  background-color: #fff;
  margin-bottom: 16px;
  margin-left: 16px;
  /* margin: 0 10px; */
  /*box-shadow: 0 0 0 1px rgba(114, 114, 114, 0.3);*/
}

.el-dropdown-link {
  font-size: 12px;
}

.main-cont {
  /* margin: 10px 10px 0 10px; */
  margin-left: 16px;
  background-color: #fff;
  padding: 16px;
  overflow: auto;
}

.tab-cont {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.el-tab-pane {
  height: 100%;
  background: #f2f5f3;
}
</style>
