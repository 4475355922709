<template>
  <!-- <el-dialog
    :title="`${form.id ? '编辑' : '新增'}书籍`"
    :visible.sync="show"
    width="550px"
    :before-close="handleClose"
    append-to-body
  > -->
  <div class="book-edit">
    <el-form label-width="100px">
      <el-form-item label="名称">
        <el-input
          v-model.trim="form.bookName"
          placeholder="请输入书籍名称"
          style="width: 400px"
        />
      </el-form-item>
      <el-form-item label="别名">
        <el-input
          v-model.trim="booksingleName"
          placeholder="请输入书籍别名"
          style="width: 300px"
        />
        <el-button
          style="margin-left: 26px"
          type="success"
          size="mini"
          @click="handleAddBookAlias"
          >添加</el-button
        >
        <div class="tag-box">
          <el-tag
            style="margin-right: 5px"
            size="small"
            v-for="(item, index) in bookOtherName"
            :key="index"
            closable
            @close="handleCloseTag(index)"
            >{{ item }}</el-tag
          >
        </div>
        <p class="tips">
          用于解决同步消耗数据时书名与计划名不一致导致的数据错乱问题
        </p>
      </el-form-item>
      <el-form-item label="最低roi">
        <el-input-number
          v-model="form.minRate"
          controls-position="right"
          :min="0"
          :precision="2"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="转化成本">
        <div class="tag-roi-box" v-for="(m, n) in tagList">
          <div class="roi-input">
            <el-input
              controls-position="right"
              v-model.number="m.value"
              type="number"
              style="width: 210px"
              placeholder="转化成本"
            >
              <el-select
                style="width: 100px"
                v-model="m.label"
                slot="prepend"
                placeholder="选择标签"
              >
                <el-option
                  v-for="(m, n) in labelListShow"
                  :label="m.label"
                  :value="m.value"
                  :key="n"
                ></el-option>
              </el-select>
            </el-input>
          </div>
          <div
            v-if="n == tagList.length - 1"
            class="add-tag"
            @click="handleAddTag"
          >
            <i class="el-icon-plus"></i>
          </div>
          <div
            v-if="n != tagList.length - 1"
            class="add-tag"
            style="background-color: #fd5d5a"
            @click="handleDeleteTag(n)"
          >
            <i class="el-icon-minus"></i>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="书籍分类">
        <el-select v-model="oneBookClassChange" placeholder="请选择">
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选书人">
        <el-select v-model="byUser" clearable placeholder="请选择">
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="测书人">
        <!-- 单独用户 -->
        <el-select
          v-model="testUserIds"
          filterable
          clearable
          :multiple="true"
          placeholder="测书人"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker
          v-model="startDate"
          type="date"
          align="right"
          unlink-panels
          clearable
          range-separator="-"
          value-format="yyyy-MM-dd"
          placeholder="开始时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          v-model="endDate"
          type="date"
          align="right"
          unlink-panels
          clearable
          range-separator="-"
          value-format="yyyy-MM-dd"
          placeholder="结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="书籍状态">
        <el-select v-model="oneBookStatus" placeholder="请选择">
          <el-option
            v-for="item in bookStatus"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { labelList } from "@/assets/js/options";
import { editBook } from "@/api/account";
import { mapGetters } from "vuex";
import { getClassifyList } from "@/api/classify";
import { getOfficialList } from "../../../api/account";
export default {
  name: "edit",
  props: {
    show: Boolean,
    book: Object,
    categoryId: [Number, String],
  },
  data() {
    return {
      form: {},
      classifyList: [],
      oneBookClassChange: null,
      oneBookStatus: null,
      bookStatus: [
        {
          name: "测书",
          code: 1,
        },
        {
          name: "观察",
          code: 2,
        },
        {
          name: "放量",
          code: 3,
        },
        {
          name: "放弃",
          code: 4,
        },
      ],
      byUser: null,
      bookOtherName: [],
      booksingleName: "",
      startDate: null,
      endDate: null,
      testUserIds: [],
      test: "",
      labelList,
      tagList: [
        {
          label: "",
          value: "",
        },
      ],
    };
  },
  computed: {
    labelListShow() {
      return this.labelList.filter((item) => {
        return item.show;
      });
    },
    ...mapGetters(["userList"]),
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          const { book } = this;
          this.bookOtherName = [];
          this.booksingleName = "";
          if (book) {
            this.tagList = [];
            if (this.book.roiSetting) {
              let tmpTag = JSON.parse(this.book.roiSetting);
              for (const key in tmpTag) {
                this.tagList.push({
                  label: parseInt(key),
                  value: tmpTag[key],
                });
              }
            }
            if (!this.tagList.length) {
              this.tagList = [
                {
                  label: "",
                  value: "",
                },
              ];
            }
            this.oneBookClassChange =
              book.categoryId === -1 ? null : book.categoryId;
            this.form = {
              id: book.id,
              bookName: book.bookName,
              // bookAlias: book.bookAlias,
              minRate: book.minRate,
              selectBookUser: book.selectBookUser,
              remark: book.remark || null,
            };
            this.booksingleName = "";
            if (book.bookAlias === "") {
              this.bookOtherName = [];
            } else {
              this.bookOtherName = book.bookAlias.split(",");
            }
            this.byUser = book.selectBookUser;
            this.oneBookStatus = this.book.status || null;
            this.startDate = this.book.startDate || null;
            this.endDate = this.book.endDate || null;
            if (this.book.testUsersList) {
              this.testUserIds = this.book.testUsersList.map((item) => {
                return item.testUserId;
              });
            } else {
              this.testUserIds = [];
            }
          } else {
            this.form = {};
            this.testUserIds = [];
            this.startDate = null;
            this.endDate = null;
            this.status = null;
            this.tagList = [
              {
                label: "",
                value: "",
              },
            ];
          }
        } else {
          this.oneBookClassChange = null;
          this.byUser = null;
          this.form = {};
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleDeleteTag(index) {
      this.tagList.splice(index, 1);
    },
    handleAddTag() {
      this.tagList.push({
        label: "",
        value: "",
      });
    },
    // 删除别名
    handleCloseTag(index) {
      this.bookOtherName.splice(index, 1);
    },
    // 添加别名
    handleAddBookAlias() {
      if (!this.booksingleName) {
        return this.$message.warning("书籍别名不能为空");
      }
      this.bookOtherName.push(this.booksingleName);
      this.booksingleName = "";
    },
    handleSubmit() {
      let tmpTag = {};
      this.tagList.forEach((item) => {
        if (item.label !== "" && item.value !== "") {
          tmpTag[item.label] = item.value;
        }
      });

      if (this.form.bookName) {
        this.form.categoryId = this.oneBookClassChange;
        this.form.status = this.oneBookStatus;
        this.form.selectBookUser = this.byUser;
        this.form.startDate = this.startDate;
        this.form.endDate = this.endDate;
        this.form.testUserIds = this.testUserIds;
        if (this.booksingleName) {
          this.bookOtherName.push(this.booksingleName);
        }
        editBook({
          ...this.form,
          roiSetting: tmpTag,
          bookAlias: this.bookOtherName.join(","),
        }).then(() => {
          this.$message.success("保存成功");
          this.form = {};
          this.oneBookStatus = null;
          this.startDate = null;
          this.endDate = null;
          this.handleClose();
          this.$emit("refresh");
        });
      } else {
        this.$message.error("请输入书籍名称");
      }
    },
    handleClose() {
      this.form = {};
      this.oneBookStatus = null;
      this.startDate = null;
      this.endDate = null;
      this.testUserIds = null;
      this.$emit("close");
    },
  },
  created() {
    // this.oneBookClassChange = this.categoryId;
    getClassifyList({
      type: 3,
    })
      .then((res) => {
        this.classifyList = res;
      })
      .finally(() => (this.loading = false));
  },
};
</script>

<style scoped lang="scss">
.tips {
  font-size: 12px;
  color: #999;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
.book-edit {
  .tag-roi-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & ~ .tag-roi-box {
      margin-top: 4px;
    }
    .add-tag {
      margin-left: 4px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #00bf8a;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        font-size: 10px;
        color: #fff;
      }
    }
  }
}
</style>
